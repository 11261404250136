import { Pages } from './pages.js'
import site_config from '/site_config.json'
import site_file from '/data/site.json'
import NavigationWidget from './navigation_widget.jsx'
//import '/scss/main.scss'

let fetch_url;
if( import.meta.env.DEV )  {
  fetch_url = site_config.dev.url + site_config.name;
} else {
  fetch_url = site_config.production.url + site_config.name;
}

const pages = new Pages();
pages.initializeSite(site_file);

async function fetchSite() {
  return fetch(fetch_url)
    .then((response) => response.json())
    .then((data) => {
        console.log('-> data.published_at: ' + data.published_at + ' site_file.published_at: ' + site_file.published_at);
        if(data.published_at > site_file.published_at) {
          pages.initializeSite(data);
        }
      })
    .catch((error) => console.log('[[[[ERROR 2]]]] -> ' + error));  
}
fetchSite();
