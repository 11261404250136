export function Pages() {
  let page_history = [];
  
  
  function initializeSite(site) {
    console.log('<<<< INITIALIZING SITE >>>>' + site.published_at);
    catch_clicks(site);
    
    const permalink = currentPermalink();
    build(permalink, site);
    
    document.addEventListener('click', event => { 
      if( event.target.getAttribute('name') == 'send-email' ) {
        console.log('-- send-email clicked');
        event.preventDefault(); 
        send_mail_form();
        return false;
      }
    });
    
    addEventListener('popstate', () => {
      const popped_permalink = page_history.pop();
      if( popped_permalink ) {
        build(popped_permalink, site, false);
      }
    });

  }
  
  
  function currentPermalink() {
    return window.location.pathname.substr(1, window.location.pathname.length).split('.')[0];
  }
  
  
  function catch_clicks(site) {
    document.addEventListener(`click`, event => {
      const link = event.target.closest(`a`);
      
      if( link ) {
        let href = link.getAttribute('href');
        if( href == '' || href == '/') href = '/home';
        if( href.includes('http') || ( href.includes('//') ) ) {
          event.preventDefault();
          console.log('we have an external link: ' + href);
          return true;
        } else if( href.includes('mailto') ) {
          return true;
        } else {
          event.preventDefault();
          if( page_history[page_history.length] != href.substr(1, href.length) && history.state != null  ) {
            page_history.push(history.state.id);
          }
          build(href.substr(1, href.length), site, true);
          return false;
        }
      }
    });
  }
  
  
  function build(permalink, site, click_forward = true) {
    if( permalink === '') {
      if( currentPermalink() ) {
        permalink = currentPermalink();
      } else {
        permalink = 'home';
      }
    }
    
    console.log('permalink: ' + permalink + ', site: ' + site.name);
    const build_page = site.pages.find((p) => p.permalink == permalink );
    
    if( build_page.page_type == 'Redirect' ) {
      window.location.href = build_page.redirect;
      return true;
    }
    
    const body_element = document.querySelector('body');
    body_element.setAttribute('class', build_page.page_class);
    body_element.setAttribute('id', build_page.page_id);
    body_element.setAttribute('data-permalink', '/' + build_page.permalink);
    document.querySelector('meta[name="description"]').setAttribute('content', build_page.description);
    document.querySelector('meta[property="og:title"]').setAttribute('content', build_page.title);
    document.querySelector('meta[property="og:image"]').setAttribute('content', build_page.og_image);
    document.querySelector('meta[property="og:url"]').setAttribute('content', 'http://www.' + site.domain_name + '/' + permalink);
    //document.querySelector('span[name="company-name"]').innerHTML = site.organization_name;
    
    document.querySelector('article').innerHTML = build_page.article;
    document.querySelector('aside').innerHTML = build_page.aside;
    document.querySelector('title').innerHTML = build_page.title;
    document.querySelector('.banner div.gutter').innerHTML = build_page.banner;
    
    history.pushState( { id: permalink }, build_page.name, build_page.permalink );
    
    if(click_forward) {
      window.scrollTo(0, 0);
    }
  }
  
  
  function send_mail_form() {
    send_mail();
    /*
    if( site.recaptcha_secret_key !== undefined ) {
      verify_google_recaptcha()
    } else {
      send_mail();
    }
    */
  }
  
  
  function send_mail() {
    console.log('-- send_mail fired');
    const sender_name = document.querySelector('#form_name').value;
    const sender_email = document.querySelector('#form_email').value;
    const sender_phone = document.querySelector('#form_phone').value;
    const sender_message = document.querySelector('#form_message').value;
    const mail_form = document.querySelector('form[action="/mailer"]');
    var body;
    body = 'From: ' + sender_name + ' ' + sender_email + '<br>\n\r ';
    body = body + 'Phone: ' + sender_phone + '<br>\n\r';
    body = body + 'Message: ' + sender_message;
    
    mail_form.insertAdjacentHTML('beforeend', '<div class="mail-form-overlay" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1000; background-color: rgba(255, 255, 255, 0.9); display: flex; align-items: center; justify-content: center;"><img style="width: 64px; height: 64px;" src="/images/​​wait-spinner-1.gif"></div>');
  
    Email.send({
      SecureToken : '07f389a9-f265-49e2-b533-dc326c88329d',
      To : site.send_to,
      From : site.send_from,
      ReplyAddress : sender_name + ' <' + sender_email + '>',
      Subject : "New message received from remote website",
      Body : body
    }).then(function(messsage) {
      if (typeof close_modal === "function") close_modal();
      mail_form.innerHTML = "<h3>Thanks - your message has been sent.</h3>";
      //document.querySelector('mail-form-overlay').remove();
    });

  }
  
  
  function verify_google_recaptcha() {
    return true;
    
    /*
    var mail_form = $('form[action="/mailer"]');
    var secret_key = site.recaptcha_secret_key;
    var recaptcha_response = $('#g-recaptcha-response').val();
    
    if(site.local_dev == 'true') {
      var url = 'http://localhost:3000/mailer/verify_google_recaptcha'
    } else {
      var url = 'https://pages.mitymo.com/mailer/verify_google_recaptcha'
    }
    
    if( recaptcha_response !== undefined ) {
      // do the verification
      $.ajax({
        url: url, 
        type: 'POST',
        data: { secret_key: secret_key, recaptcha_response: recaptcha_response },
        async: false,
        complete: function(result) {
          if(result.responseText == 'true'){
            send_mail();
          } else {
            $(mail_form).prepend("<h3>Error sending email.</h3>");
          }
        }
      });
    } else {
      // nothing to verify
      return true;
    }
    */
  }
  
  
  function addBackground() {
    const body = document.querySelector('body');
    
    body.insertAdjacentHTML('beforeend', '<div class="background-overlay" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1000; background-color: rgba(0, 0, 0, 0.8); display: flex; align-items: center; justify-content: center;"></div>');
    
    const backgroundOverlay = document.querySelector('.background-overlay');
    
    window.addEventListener('resize', () => {
      backgroundOverlay.style.width( window.width() );
      backgroundOverlay.style.height( window.height() );
    });
    
    backgroundOverlay.addEventListener('click', event => {
      backgroundOverlay.remove();
    });
  }
  
  
  return {
    catch_clicks: catch_clicks,
    initializeSite: initializeSite,
    build, build,
  }
  
  
  
}
