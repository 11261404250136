import React from 'react'
import ReactDOM from 'react-dom/client'

import { createRoot } from 'react-dom/client'
import { useState } from 'react'
import './navigation_widget.scss'
import site_file  from '/data/site.json'


export default function NavigationWidget(props) {
  const navigation = site_file.navigation;
  const [show, setShow] = useState(0);
  
  function clickNavigationButton() {
    const menu = document.querySelector('nav ul.menu');
    
    if( show == 1 ) {
      setShow(0);
      menu.style.display = 'none';
      //removeOverlay();
    } else {
      setShow(1);
      //showOverlay();
      menu.style.display = 'flex';
      menu.style.zIndex = 31;
    }
  }
  
  function clickMenuLink() {
    console.log("clickMenuLink() happening");
    const menu = document.querySelector('nav ul.menu');
    if(show == 1) {
      setShow(0);
      menu.style.display = 'none';
      removeOverlay();
      
    }
  }
  
  function showOverlay() {
    document.querySelector('body').insertAdjacentHTML('beforeend', '<div class="menu-overlay"></div>');
    document.querySelector('.menu-overlay').addEventListener('click', event => removeOverlay);
  }
  
  function removeOverlay() {
    const menu_overlay = document.querySelector('.menu-overlay');
    if( menu_overlay ) {
      menu_overlay.remove();
    }
  }
  
  function NavItem(props) {
    return <li key={props.permalink}><a href={ props.permalink } onClick={clickMenuLink} >{ props.name }</a></li>
  }
  
  function SubNavItem(props) {
    return <li key={props.permalink}><a href={ props.permalink } onClick={clickMenuLink} >{ props.name }</a><ul>
      { props.items.map((item) => add_nav_item(item) ) }
    </ul></li>
  }
  
  function add_nav_item(item) {
    if( item[2].length > 0 ) {
      return <SubNavItem key={item[1]} permalink={ '/' + item[1] } name={ item[0] } items={ item[2] } />
    } else {
      return <NavItem key={item[1]} permalink={ '/' + item[1] } name={ item[0] } />
    }
  }
  
  return (
    <>
      <nav>
        <button onClick={clickNavigationButton}><i className="fa-solid fa-bars"></i></button>
        <ul className="menu">
          { 
            navigation.map((item) =>  add_nav_item(item) )
          }
        </ul>
      </nav>
    </>
  )
}

createRoot(document.getElementById('navigation_widget')).render(<NavigationWidget />);

createRoot(document.getElementById('footer_navigation')).render(<NavigationWidget />);